import { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import InsightTable from "../../Insights/InsightTable";
import { InsightWrapper } from './MultiColumnChart.styles';
import { GetAPIRequest, MultipleGetRequestArray, MultipleGetRequest } from "../../../services";
import CONFIG from "../../../constants";
import { PageHeader, Descriptions, Row, Col, Layout, Typography, Space, Select, Spin } from "antd"
const { Option } = Select;


const MultiColumnChart = (props) => {

    const MULTICOLUMN_CHART_ID = "multicolumnChart-" + props.response.title.replace(" ", "-");
    const multicolumnChart = useRef(null);
    const [actionData, setActionData] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        var root = am5.Root.new(MULTICOLUMN_CHART_ID);
        multicolumnChart.current = root;
        setChartData(props.response.data);
        ;
        if (props.response.data.length > 0)
            loadMultiColumnChart(root);
        else
            root.dispose();



        return () => {
            root.dispose();
        }
    }, [chartData]);

    useEffect(() => {
        getTriggerTypes();
    }, []);

    const loadMultiColumnChart = (root) => {
        let dataObject = props.response.data;

        let data = [];
        dataObject.map((item, index) => {
             
            let obj = {
                name: item.name
            }
            item.data.map((item, index) => {
                obj[item.name] = parseInt(item.value);
            })
            data.push(obj);
        })

        console.log("multicolumn chart", data);

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout
        }));

        var legend = chart.children.push(
            am5.Legend.new(root, {
                centerX: am5.p50,
                x: am5.p50
            })
        );

        var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "name",
            renderer: am5xy.AxisRendererX.new(root, {
                cellStartLocation: 0.1,
                cellEndLocation: 0.9
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));

        xAxis.data.setAll(data);

        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {}),
            min: 0
        }));

        function getColorByType(fieldName) {
            var color = props.response.colors;

            return color[fieldName] == undefined ? "#6e161f" : color[fieldName];
        }


        function getTooltipValue(response, name, fieldName, keyName, series) {
            // var tooltipText = "";
          
            var tooltip;
            var tooltipText = {};
            response.map((item, index) => {
                if (item.name.toLowerCase() == "actioned") {
                    var filteredItem = item.data.filter(x => x.name == fieldName)
                    filteredItem.map((subitem, index) => {
                        tooltipText['ParentText'] = subitem.name + " : " + subitem.value;
                        if (subitem.children.length > 0) {
                            subitem.children.map((childitem, index) => {
                                tooltipText[childitem.name] = childitem.name + " : " + childitem.value;
                            })
                        }
                    })
                }
                else {
                    tooltipText = {}
                    var filteredItem = item.data.filter(x => x.name == fieldName);
                    filteredItem.map((subitem, index) => {

                        tooltipText['ParentText'] = subitem.name + " : " + subitem.value;
                    })
                }
               
                var tooltip = am5.Tooltip.new(root, {
                    labelText: (tooltipText.Call != undefined && tooltipText.Email != undefined) ? `[bold]${tooltipText.ParentText}\n ${tooltipText.Call}\n ${tooltipText.Email}  ` : props.response.isPercentage ? "{valueYField}:{valueY}%" : "{valueYField}:{valueY}",
                });

                tooltip.get("background").setAll({
                    stroke: am5.color(0x000000),
                    strokeOpacity: 0.8
                });
                series.set("tooltip", tooltip);
            })

            return tooltip;
        }

        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        function makeSeries(name, fieldName, keyName) {

            var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: fieldName,
                categoryXField: "name",
                fill: getColorByType(fieldName)
            }));



            series.columns.template.setAll({
                tooltipText: props.response.isPercentage ? "{valueYField}:{valueY}%" : "{valueYField}:{valueY}",
                // tooltipText: getTooltipValue(props.response.data, name, fieldName, keyName, series),
                width: am5.percent(90),
                tooltipY: 0
            });


            series.columns.template.events.on("click", function (ev) {
                setActionData(ev.target.dataItem.dataContext);
                console.log("Clicked on a column", ev.target.dataItem.dataContext);

                /*
                check if the drilldown url available call the function
                 */
                setIsLoading(true);
                getDistributionsByType(ev.target.dataItem.dataContext)
            });

            series.data.setAll(data);

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear();

            series.bullets.push(function () {

                return am5.Bullet.new(root, {
                    locationY: 0,
                    sprite: am5.Label.new(root, {
                        text: "{valueY}",
                        fill: root.interfaceColors.get("alternativeText"),
                        centerY: 0,
                        centerX: am5.p50,
                        populateText: true
                    })
                });
            });

            //legend.data.push(series);
        }


        var prevItem = "";
        var prevName = ""
        if (data.length > 0) {
            for (const [key, value] of Object.entries(data[0])) {

                if (key == "name")
                    var keyName = value;
                if (key !== "name") {
                    var currItem = key.split(" - ")[0];
                    if (currItem !== prevItem) {
                        prevItem = currItem;
                        console.log("make series", key)
                        makeSeries(key, key, keyName, false,);
                    } else {
                        makeSeries(key, key, keyName, false);
                    }
                }
            }
        }

        chart.appear(1000, 100);
    }

    const [triggerTypes, setTriggerTypes] = useState([]);
    const [selectedTrigger, setSelectedTrigger] = useState("");

    function getDistributionsByType(data) {

        if (data != null) {
            var selectedTrigger = localStorage.getItem("SelectedTrigger");
            let TriggersType = JSON.parse(localStorage.getItem("TriggersType"));
            var triggerObj = TriggersType.filter(x => x.text == selectedTrigger)[0];
            var triggerName = "";
            if (data.name == "Actioned/Completed")
                triggerName = "Completed";
            else
                triggerName = data.name

            GetAPIRequest({
                url: `${CONFIG.api.getTriggerList + triggerObj.text + "/" + triggerName}`
            }).then((response) => {
                setIsLoading(false);
                setTableData(response.data);
                setTableHeaders(response.headers);
            })
        }
    }



    const getTriggerTypes = () => {
        GetAPIRequest({
            url: `${CONFIG.api.getTriggerTypes}`
        }).then((response) => {

            setSelectedTrigger(response[0].text);
            setTriggerTypes(response);
            localStorage.setItem("SelectedTrigger", response[0].text);
            localStorage.setItem("TriggersType", JSON.stringify(response));
            console.log("trigger types", response)
        })
    }

    const getDetailsByTrigger = (event) => {
        setIsLoading(true);
        setTableData([]);
        setTableHeaders([]);
        setSelectedTrigger(event);
        var triggerObj = triggerTypes.filter(x => x.text == event)[0];
        GetAPIRequest({
            url: `${CONFIG.api.getTriggerByType + triggerObj.value}`
        }).then((response) => {
            setIsLoading(false);
            setChartData(response.data)
            props.response.data = response.data;
        })
    }

    return (
        <>

            <div style={{
                // float: "right",
                // paddingRight: "30px"
                paddingBottom: "1%",
                paddingRight: "4%"
            }}>
                <Select onChange={getDetailsByTrigger}
                    style={{ minWidth: "24%", float: "right" }}
                    value={selectedTrigger}
                >
                    {
                        triggerTypes.map((item, index) => (
                            <Option key={index} value={item.text}>
                                {item.text.toUpperCase()}
                            </Option>
                        ))

                    }
                </Select>

            </div>
            <Spin size="large" spinning={isLoading}>
                {
                    props.response && <div id={MULTICOLUMN_CHART_ID} style={{

                        height: '500px',
                        marginTop: "30px",
                        marginLeft: "30px",
                        marginRight: "30px"
                    }}></div>
                }


                {tableData.length > 0 && tableHeaders.length > 0 &&

                    <InsightWrapper>
                        <h2>Trigger Details</h2>
                        <InsightTable data={tableData} headers={tableHeaders} />
                    </InsightWrapper>}
            </Spin>

        </>
    )
}
export default MultiColumnChart;