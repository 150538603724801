import { useEffect, useRef } from "react"
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";


const MultiLineSeriesChart = (props) => {

    const MULTILINESERIES_CHART_ID = "multiLineSeriesChart-" + props.title.replace(" ", "-");
    const multiLineSeriesChart = useRef(null);
    useEffect(() => {
        
        let root = am5.Root.new(MULTILINESERIES_CHART_ID);
        multiLineSeriesChart.current = root;

        if (props.response.length > 0) {
            loadMultiLineSeriesChart(root);
        }
        else {
            root.dispose();
        }

        return () => {
            root.dispose();
        }
    }, []);


    const loadMultiLineSeriesChart = (root) => {
        let data = props.response;
        // let data =
        //     [
        //         {
        //             "name": "dismissed",
        //             "data": [
        //                 {
        //                     "name": "2022-04-17",
        //                     "value": 54,
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-03-17",
        //                     "value": 80,
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-01-17",
        //                     "value": 4,
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-02-17",
        //                     "value": 103,
        //                     "children": [
        //                     ]
        //                 }
        //             ]
        //         },
        //         {
        //             "name": "actioned",
        //             "data": [
        //                 {
        //                     "name": "2022-04-17",
        //                     "value": 1634,
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-02-17",
        //                     "value": 1528,
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-01-17",
        //                     "value": 139,
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-02-17",
        //                     "value": 1650,
        //                     "children": [
        //                     ]
        //                 }
        //             ]
        //         },
        //         {
        //             "name": "call",
        //             "data": [
        //                 {
        //                     "name": "2022-04-17",
        //                     "value": 1393,
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-03-17",
        //                     "value": 1326,
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-01-17",
        //                     "value": 118,
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-02-17",
        //                     "value": 1356,
        //                     "children": [
        //                     ]
        //                 }
        //             ]
        //         },
        //         {
        //             "name": "email",
        //             "data": [
        //                 {
        //                     "name": "2022-04-17",
        //                     "value": 260,
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-03-17",
        //                     "value": 215,
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-01-17",
        //                     "value": 29,
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-02-17",
        //                     "value": 311,
        //                     "children": [
        //                     ]
        //                 }
        //             ]
        //         },
        //         {
        //             "name": "expired",
        //             "data": [
        //                 {
        //                     "name": "2022-04-17",
        //                     "value": 295,
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-03-17",
        //                     "value": 395,
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-01-17",
        //                     "value": 14,
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-02-17",
        //                     "value": 1643,
        //                     "children": [
        //                     ]
        //                 }
        //             ]
        //         },
        //         {
        //             "name": "pending",
        //             "data": [
        //                 {
        //                     "name": "2022-04-17",
        //                     "value": "0",
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-03-17",
        //                     "value": "0",
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-01-17",
        //                     "value": "0",
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-02-17",
        //                     "value": "0",
        //                     "children": [
        //                     ]
        //                 }
        //             ]
        //         },
        //         {
        //             "name": "total_triggers",
        //             "data": [
        //                 {
        //                     "name": "2022-04-17",
        //                     "value": 1989,
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-03-17",
        //                     "value": 2003,
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-01-17",
        //                     "value": 157,
        //                     "children": [
        //                     ]
        //                 },
        //                 {
        //                     "name": "2022-02-17",
        //                     "value": 3396,
        //                     "children": [
        //                     ]
        //                 }
        //             ]
        //         }
        //     ]




        root.setThemes([am5themes_Animated.new(root)]);

        var chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                maxTooltipDistance: 0,
                pinchZoomX: true,
            })
        );

        chart.get("colors").set("colors", [
            am5.color("#1F77B4"),
            am5.color("#FF7F27"),
            am5.color("#2CA02C"),
            am5.color("#D62728"),
            am5.color("#9467BD"),
            am5.color("#8C564B"),
            am5.color("#E377C3")
        ]);

        var date = new Date();
        date.setHours(0, 0, 0, 0);
        var value = 100;

        var xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                maxDeviation: 0.2,
                baseInterval: {
                    timeUnit: "day",
                    count: 1,
                },
                renderer: am5xy.AxisRendererX.new(root, {}),
                tooltip: am5.Tooltip.new(root, {}),
            })
        );

        var yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {}),
            })
        );

        for (var i = 0; i < data.length; i++) {
            var series = chart.series.push(
                am5xy.LineSeries.new(root, {
                    name: data[i].name,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "value",
                    valueXField: "name",
                    legendValueText: "{valueY}",
                    tooltip: am5.Tooltip.new(root, {
                        pointerOrientation: "horizontal",
                        labelText: "{valueY}",
                    }),
                })
            );

            series.bullets.push(function (root, series, dataItem) {
                return am5.Bullet.new(root, {
                    sprite: am5.Circle.new(root, {
                        radius: 5,
                        fill: series.get("fill")
                    })
                });
            });

            data[i].data.map((d, idx) => {
                let date = new Date(d.name);
                date.setHours(0, 0, 0, 0);
                d.name = date.getTime();
            });


            series.data.setAll(data[i].data);
            series.appear();
        }

        var cursor = chart.set(
            "cursor",
            am5xy.XYCursor.new(root, {
                behavior: "none",
            })
        );
        cursor.lineY.set("visible", false);

        // chart.set(
        //     "scrollbarX",
        //     am5.Scrollbar.new(root, {
        //         orientation: "horizontal",
        //     })
        // );

        // chart.set(
        //     "scrollbarY",
        //     am5.Scrollbar.new(root, {
        //         orientation: "vertical",
        //     })
        // );

        var legend = chart.rightAxesContainer.children.push(
            am5.Legend.new(root, {
                width: 300,
                paddingLeft: 15,
                height: am5.percent(100),
            })
        );

        // When legend item container is hovered, dim all the series except the hovered one
        legend.itemContainers.template.events.on("pointerover", function (e) {
            var itemContainer = e.target;

            // As series list is data of a legend, dataContext is series
            var series = itemContainer.dataItem.dataContext;

            chart.series.each(function (chartSeries) {
                if (chartSeries != series) {
                    chartSeries.strokes.template.setAll({
                        strokeOpacity: 0.15,
                        stroke: am5.color(0x000000),
                    });
                } else {
                    chartSeries.strokes.template.setAll({
                        strokeWidth: 3,
                    });
                }
            });
        });

        // When legend item container is unhovered, make all series as they are
        legend.itemContainers.template.events.on("pointerout", function (e) {
            var itemContainer = e.target;
            var series = itemContainer.dataItem.dataContext;

            chart.series.each(function (chartSeries) {
                chartSeries.strokes.template.setAll({
                    strokeOpacity: 1,
                    strokeWidth: 1,
                    stroke: chartSeries.get("fill"),
                });
            });
        });

        legend.itemContainers.template.set("width", am5.p100);
        legend.valueLabels.template.setAll({
            width: am5.p100,
            textAlign: "right",
        });

        legend.data.setAll(chart.series.values);

        chart.appear(1000, 100);
    }


    return (
        <>
            {props.response && <div id={MULTILINESERIES_CHART_ID} style={{
                width: '100%',
                height: '500px',
                marginTop: "30px",
                marginLeft: "30px",
                marginRight: "30px"
            }}></div>
            }
        </>
    );
}

export default MultiLineSeriesChart;