import { useEffect, useRef } from "react"
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import { Button } from "antd";


const StackedColumnChart = (props) => {

    const STACKEDCOLUMN_CHART_ID = "StackedColumnChart-" + props.title.replace(" ", "-");
    const stackedColumnChart = useRef(null);
    let exporting = {};
    useEffect(() => {
        let chartDiv = am5.Root.new("StackedColumnChart-" + props.title.replace(" ", "-"));
        stackedColumnChart.current = chartDiv;
        if (props.response) {
            loadStackedColumnChart(chartDiv);
        }
        else {
            chartDiv.dispose();
        }
        return () => {
            chartDiv.dispose();
        }
    }, []);

    const loadStackedColumnChart = (root) => {
        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
            am5themes_Animated.new(root)
        ]);


        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout
        }));

        chart.get("colors").set("colors", [
            am5.color("#009fb8"),
            am5.color("#661B77"),
            am5.color("#9467BD"),
            am5.color("#8C564B"),
            am5.color("#E377C3"),
            am5.color("#1F77B4"),
            am5.color("#FF7F27")
        ]);

        // Add scrollbar
        // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
        // chart.set("scrollbarX", am5.Scrollbar.new(root, {
        //     orientation: "horizontal"
        // }));

        var data = props.response;
        // [{
        //     "name": "HCPs total",
        //     "saphnelo": 11.18,
        //     "benlysta": 88.82,
        //     "barText": "2344 (0.7%)",
        //     "rank": 1
        // },
        // {
        //     "name": "HCPs not triggered", "saphnelo": 14.01, "benlysta": 85.99, "barText": "871 (0.26%)",
        //     "rank": 2
        // },
        // {
        //     "name": "HCPs triggered", "saphnelo": 9.5, "benlysta": 90.5, "barText": "1473 (0.44%)",
        //     "rank": 3
        // },
        // {
        //     "name": "HCPs triggers published", "saphnelo": 9.65, "benlysta": 90.35, "barText": "1441 (42.43%)",
        //     "rank": 4
        // },
        // {
        //     "name": "HCPs trigger Actioned", "saphnelo": 11.96, "benlysta": 88.04, "barText": "945 (57.13%)",
        //     "rank": 5
        // },
        // {
        //     "name": "HCPs trigger Dismissed", "saphnelo": 2.63, "benlysta": 97.37, "barText": "38 (38.38%)",
        //     "rank": 6
        // },
        // {
        //     "name": "HCPs trigger Expired", "saphnelo": 5.46, "benlysta": 94.54, "barText": "458 (27.88%)",
        //     "rank": 7
        // }]



        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "name",
            renderer: am5xy.AxisRendererX.new(root, {
                cellStartLocation: 0.1,
                cellEndLocation: 0.9
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));

        xAxis.data.setAll(data);

        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            min: 0,
            max: 100,
            numberFormat: "#'%'",
            extraMax: 0.05,
            calculateTotals: true,
            renderer: am5xy.AxisRendererY.new(root, {})
        }));


        // Add legend
        // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
        var legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50
        }));


        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        function makeSeries(name, fieldName, total) {
            var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                stacked: true,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: fieldName,
                valueYShow: "valueYTotalPercent",
                categoryXField: "name"
            }));

            series.columns.template.setAll({
                tooltipText: "{categoryX}:{valueYTotalPercent.formatNumber('#.#')}%",
                tooltipY: am5.percent(10)
            });
            series.data.setAll(data);

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear();

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    sprite: am5.Label.new(root, {
                        text: "{valueYTotalPercent.formatNumber('#.#')}%",
                        fill: root.interfaceColors.get("alternativeText"),
                        centerY: am5.p50,
                        centerX: am5.p50,
                        populateText: true
                    })
                });
            });

            if (total) {

                series.bullets.push(function () {
                    var totalLabel = am5.Label.new(root, {
                        text: "{valueY}",
                        fill: root.interfaceColors.get("text"),
                        centerY: am5.p100,
                        centerX: am5.p50,
                        populateText: true,
                        textAlign: "center"
                    });

                    totalLabel.adapters.add("text", function (text, target) {
                        var dataContext = target.dataItem.dataContext;
                        var val = dataContext.barText
                        return val;
                    });

                    return am5.Bullet.new(root, {
                        locationX: 0.5,
                        locationY: 1,
                        sprite: totalLabel
                    });
                });
            }

            legend.data.push(series);
        }

        var prevItem = "";
        if (data.length > 0) {
            var count = 0;
            for (const [key, value] of Object.entries(data[0])) {
                //let lastkey = Object.keys(data[0])


                console.log(Object.keys(data[0]).pop());
                if (key !== "name" && key != "barText" && key != "rank") {
                    count++;
                    var currItem = key.split(" - ")[0];

                    if (currItem !== prevItem) {
                        prevItem = currItem;

                        if (count == 2)
                            makeSeries(key, key, true);
                        else
                            makeSeries(key, key, false);
                    } else {
                        makeSeries(key, key, false);
                    }
                }
            }
        }


        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100);

    }

    return (
        <>
            {
                props.response &&
                <div id={STACKEDCOLUMN_CHART_ID} style={{
                    width: '100%',
                    height: '500px',
                    margin: "auto"
                }}></div>
            }
        </>
    )
}


export default StackedColumnChart;