import { useEffect, useRef } from "react"
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import { Button } from "antd";

const StackedBarChart = (props) => {
 
    const STACKEDBAR_CHART_ID = "StackedBarChart-" + props.title.replace(" ", "-");
    const stackedBarChart = useRef(null);
    let exporting = {};
    useEffect(() => {
        let chartDiv = am5.Root.new("StackedBarChart-" + props.title.replace(" ", "-"));
        stackedBarChart.current = chartDiv;
        if (props.response) {
            loadStackedBarChart(chartDiv);
        }
        else {
            chartDiv.dispose();
        }
        return () => {
            chartDiv.dispose();
        }
    }, []);

    const loadStackedBarChart = (root) => {

        let dataObject = props.response;
        let data = [];
        dataObject.map((item, index) => {
            let obj = {
                name: item.name
            }
            item.data.map((item, index) => {
                if (parseInt(item.value) > 0)
                    obj[item.name] = parseInt(item.value);
                else
                    obj[item.name] = "";
            })
            data.push(obj);
        })

        console.log("multicolumn chart", data);

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout
        }));
        chart.get("colors").set("colors", [
            am5.color("#1F77B4"),
            am5.color("#FF7F27"),
            am5.color("#2CA02C"),
            am5.color("#D62728"),
            am5.color("#9467BD"),
            am5.color("#8C564B"),
            am5.color("#E377C3")
        ]);

        // Add scrollbar
        // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
        // chart.set("scrollbarX", am5.Scrollbar.new(root, {
        //     orientation: "horizontal"
        // }));

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "name",
            renderer: am5xy.AxisRendererX.new(root, {}),
            tooltip: am5.Tooltip.new(root, {})
        }));

        xAxis.data.setAll(data);

        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            calculateTotals: true,
            min: 0,
            extraMax: 0.1,
            renderer: am5xy.AxisRendererY.new(root, {})
        }));


        // Add legend
        // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
        var legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50
        }));

        // function getColotByType(fieldName){
        //     debugger
        //     return "red"
        // }

        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        function makeSeries(name, fieldName, total) {

            var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                stacked: true,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: fieldName,
                categoryXField: "name",
                // fill: getColotByType(fieldName)
            }));



            series.columns.template.setAll({
                tooltipText: "{valueYField}: {valueY}",
                //tooltipText: fieldName == "Total Actioned" ? "{valueYField}: {valueY}" : "",
                tooltipY: am5.percent(10)
            });
            series.data.setAll(data);

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear();

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    sprite: am5.Label.new(root, {
                        text: "{valueY}",
                        fill: root.interfaceColors.get("alternativeText"),
                        centerY: am5.p50,
                        centerX: am5.p50,
                        populateText: true
                    })
                });
            });
            if (total) {
                // series.bullets.push(function () {
                //     return am5.Bullet.new(root, {
                //         locationY: 1,
                //         sprite: am5.Label.new(root, {
                //             text: "{valueYTotal}",
                //             fill: am5.color(0x000000),
                //             centerY: am5.p100,
                //             centerX: am5.p50,
                //             populateText: true
                //         })
                //     });
                // });
                series.bullets.push(function () {
                    var totalLabel = am5.Label.new(root, {
                        text: "{valueY}",
                        fill: root.interfaceColors.get("text"),
                        centerY: am5.p100,
                        centerX: am5.p50,
                        populateText: true,
                        textAlign: "center"
                    });

                    totalLabel.adapters.add("text", function (text, target) {
                        var dataContext = target.dataItem.dataContext;
                        var val = 100
                        return val;
                    });

                    return am5.Bullet.new(root, {
                        locationX: 0.5,
                        locationY: 1,
                        sprite: totalLabel
                    });
                });
            }

            legend.data.push(series);
        }

        var prevItem = "";
        if (data.length > 0) {
            for (const [key, value] of Object.entries(data[0])) {
                let lastkey = Object.keys(data[0]).pop();
                console.log(Object.keys(data[0]).pop());
                if (key !== "name") {
                    var currItem = key.split(" - ")[0];
                    if (currItem !== prevItem) {
                        prevItem = currItem;
                        if (lastkey == currItem)
                            makeSeries(key, key, false);
                        else
                            makeSeries(key, key, false);
                    } else {
                        makeSeries(key, key, false);
                    }
                }
            }
        }


        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100);

        exporting = am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {})
        });
    }

    function downloadChartImage() {
        exporting.download("png");
    }

    return (
        <>
            <div >
                <Button onClick={downloadChartImage}>Download Chart As Image</Button>
            </div>

            {
                props.response &&
                <div id={STACKEDBAR_CHART_ID} style={{
                    width: '100%',
                    height: '500px',
                    margin: "auto"
                }}></div>
            }
        </>
    )
}

export default StackedBarChart